import React from "react";
import config from "../config";

import "./HelpDeskBtn.css";

export default class HelpDeskBtn extends React.Component {
	handleAskSupport = () => {
		if (this.props.isWhiteLabel) {
			const supportEmail = this.props.isCoBranded
				? this.props.dedicatedSupportEmail
					? "support." + window.appName + "@forekasts.app"
					: config.appParams[config.defaultAppName].supportEmail
				: "support." + window.appName + "@previsionnel.app";
			window.open("mailto:" + supportEmail);
		} else if (window.appName === "forekasts-africa") {
			const supportEmail = config.appParams[window.appName].supportEmail;
			window.open("mailto:" + supportEmail);
		}
	};

	render() {
		return (
			<button className="helpDeskBtn" onClick={this.handleAskSupport} title="Contacter le support">
				<i className="bi bi-envelope-open" />
			</button>
		);
	}
}
