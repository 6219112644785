export default {
  auth: {
    server : {
            "aws" : 'https://authval.forekasts.fr',
            "ows" : 'https://auth-alpharlh.forekasts.fr',
    },
    uri : '/auth',
  },
  register: {
    uri : '/register',
  },
  api: {
    server : {
            "aws" : 'https://apival.forekasts.fr',
            "ows" : 'https://api-alpharlh.forekasts.fr',
    },
    root: "/api/v2",
  },
  gtmTracking: false,
  disableCrmLog: true,
  ownAppNames: [
		"evaltonbiz",
		"ysibiz",
		"ebizview",
	  	"forekasts-africa",
	],
  defaultAppName: "evaltonbiz",
  appParams: {
        "evaltonbiz": {
                publicSiteUrl: "https://www.forekasts.fr",
                capitalizedName: "Forekasts",
                allCapsName: "FOREKASTS",
                contactEmail: "contact@forekasts.fr",
                supportEmail: "support@forekasts.fr",
                //logoFileName: "EVALTONBIZ-Logo_path.svg",
		svgLogoFileName: "Logo_Forekasts_blanc.svg",
		svgCompactLogoFileName: "Sigle Forekasts.svg",
		loginPageExtContentUrl: "https://forekasts.fr/connexion-banniere",
                cguFileName: "CGU_Forekasts.pdf",
                cgvFileName: "CGV_Forekasts.pdf",
		stripePublicKey: "pk_live_BcydCbbHZzmCXcqCfU2oAfKP",
        },
        "ysibiz": {
                publicSiteUrl: "https://www.ysibiz.com",
                capitalizedName: "Ysibiz",
                allCapsName: "YSIBIZ",
                contactEmail: "contact@ysibiz.com",
                supportEmail: "support@ysibiz.com",
                svgLogoFileName: "logo-ysibiz.svg",
		svgCompactLogoFileName: "logo-ysibiz.svg",
		pngLogoFileName: "logo-ysibiz-2020.png",
		loginPageExtContentImg: "background/repreneurs-investisseurs-100pct-1920x1080-min.jpg",
                cguFileName: "CGU_Ysibiz.pdf",
                cgvFileName: "CGV_Ysibiz.pdf",
		stripePublicKey: "pk_live_5DxZFqX9vbXXBK5hFmNDVh2y00D7uK7GS4",
        },
	"ebizview": {
		publicSiteUrl: "https://www.ebizview.co",
		capitalizedName: "Ebizview",
		allCapsName: "EBIZVIEW",
		contactEmail: "contact@ebizview.co",
		supportEmail: "support@ebizview.co",
		svgLogoFileName: "logo-ebizview.svg",
		svgCompactLogoFileName: "logo-ebizview.svg",
		pngLogoFileName: "ebizview_logo.png",
		loginPageExtContentImg: "background/entrepreneurs-ebizview.jpg",
		// cguFileName: "CGU_Forekasts.pdf",
		// cgvFileName: "CGV_Forekasts.pdf",
		// stripePublicKey: "pk_test_CzQhZxz9kHWoI4ZAdgi3HleN",
		// stripePublicKey: "pk_live_BcydCbbHZzmCXcqCfU2oAfKP",
	},
		"forekasts-africa": {
			publicSiteUrl: "https://www.forekasts.africa",
			capitalizedName: "Forekasts",
			allCapsName: "FOREKASTS",
			contactEmail: "support@forekasts.africa",
			supportEmail: "support@forekasts.africa",
			svgLogoFileName: "Logo_Forekasts_blanc.svg",
			svgCompactLogoFileName: "Sigle Forekasts.svg",
			// pngLogoFileName: "ebizview_logo.png",
			loginPageExtContentImg: "background/entrepreneurs-ebizview.jpg",
			cguFileName: "CGU_Forekasts.pdf",
			cgvFileName: "CGV_Forekasts.pdf",
			// stripePublicKey: "pk_test_CzQhZxz9kHWoI4ZAdgi3HleN",
			// stripePublicKey: "pk_live_BcydCbbHZzmCXcqCfU2oAfKP",
		},
  },
};
