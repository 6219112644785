import axios from "axios";
import config from "../config";

function isCrmLogDisabled() {
	return config.disableCrmLog;
}

export function crmLogAskNewCompany(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/ask-new-company";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogAddNewCompany(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/add-new-company";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogProjectStep(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/project-step";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogInviteCompanyContributor(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/invite-company-contributor";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogUpdateCompanyContributor(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/update-company-contributor";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogDeleteCompanyContributor(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/delete-company-contributor";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogInviteSecondaryUser(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/invite-secondary-user";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogUpdateSecondaryUser(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/update-secondary-user";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogDeleteSecondaryUser(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/delete-secondary-user";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogLogin(platform) {
	const data = {
		appName: window.appName,
		platform: platform,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/login";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogRequestNewAccount(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/request-new-account";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogActivateAccount(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/activate-account";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogSavePersonalData(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/save-personal-data";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogHelpDeskData(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/helpdesk-message";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogPostFeedback(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/post-feedback";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogSubmitContactForm(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/submit-contact-form";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogSubscribeService(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/subscribe-service";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogSubscribeOption(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/subscribe-option";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogSubscribeTrialService(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/subscribe-trial-service";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogCancelServiceSubscription(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/cancel-service";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogCancelOptionSubscription(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/cancel-option";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogSubscribeNewsletter(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/subscribe-newsletter";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogUploadAccountFile(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/upload-account-file";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogAddSimulation(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/add-simulation";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogViewContract(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/view-contract";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogViewTuto(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/view-tuto";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogViewPartnerInfos(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();
	// console.log("crmLogViewPartnerInfos", data);

	const url = config.api.server[window.serverName] + config.api.root + "/crm/view-partner";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogClicPartnerHomepage(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();
	// console.log("crmLogClicPartnerHomepage", data);

	const url = config.api.server[window.serverName] + config.api.root + "/crm/clic-partner-home";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogClicPartnerOffer(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();
	// console.log("crmLogClicPartnerOffer", data);

	const url = config.api.server[window.serverName] + config.api.root + "/crm/clic-partner-offer";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogRequestPartnerOffer(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();
	// console.log("crmLogRequestPartnerOffer", data);

	const url = config.api.server[window.serverName] + config.api.root + "/crm/request-partner-offer";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogRequestReport(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/request-report";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogDownloadReport(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/download-report";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogSelectFecForMonitoring(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/select-fec";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}

export function crmLogCreateorUpdateWhiteLabel(data) {
	data = {
		...data,
		appName: window.appName,
	};

	if (isCrmLogDisabled()) return Promise.resolve();

	const url = config.api.server[window.serverName] + config.api.root + "/crm/white-label";
	return axios
		.post(url, data, {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		})
		.catch((error) => {
			console.log(error);
		});
}
