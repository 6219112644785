import config from "../config";

export const months = ["jan", "fév", "mar", "avr", "mai", "jun", "jul", "aoû", "sep", "oct", "nov", "déc"];
export const monthsLabels = {
	jan: "Janvier",
	fév: "Février",
	mar: "Mars",
	avr: "Avril",
	mai: "Mai",
	jun: "Juin",
	jul: "Juillet",
	aoû: "Août",
	sep: "Septembre",
	oct: "Octobre",
	nov: "Novembre",
	déc: "Décembre",
};
export const monthsCapsShort = ["Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc"];
export const monthsCapsLong = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
export const monthsLowerLong = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];

export const weekdayLabels = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

export const debtAmortPeriodicities = {
	1: "mensuelle",
	3: "trimestrielle",
	12: "annuelle",
};
export const amortizationTypeLabels = {
	"linéaire": "linéaire",
	"constant": "constant",
	"in fine": "in fine",
	"rbf": "% du CA",
	"custom": "personnalisé",
};
export const frequencyLabels = {
	1: "par an",
	2: "par semestre",
	4: "par trimestre",
	12: "par mois",
	52: "par semaine",
	260: "par jour (5j/7)",
	312: "par jour (6j/7)",
	365: "par jour (7j/7)",
};

export const userRoles = ["associate", "employee", "customer", "manager", "accountant", "advisor", "banker", "investor", "partner", "other"];
export const userRoleLabels = {
	associate: "Associé",
	employee: "Collaborateur",
	customer: "Client",
	manager: "Dirigeant",
	accountant: "Expert-comptable",
	advisor: "Conseiller / expert",
	banker: "Banquier",
	investor: "Investisseur",
	partner: "Partenaire",
	other: "Autre",
};

export const kbBaseUrl = (appName, props) =>
	appName === "evaltonbiz" || props.isCoBranded ? config.appParams["evaltonbiz"].publicSiteUrl + "/documentation" : "https://documentation.previsionnel.app/knowledge-base";

export const videoTutoBaseUrl = (appName, props) =>
	appName === "evaltonbiz" || props.isCoBranded ? config.appParams["evaltonbiz"].publicSiteUrl + "/forekasts-en-video" : "https://documentation.previsionnel.app/#video-tutos";

export const defaultCompanyParameters = {
	default: {
		countrySearch: "FRANCE",
		countryName: "FRANCE",
		countryCode: "FRA",
		currencySearch: "EUR - Euro",
		currencyName: "Euro",
		currencyCode: "EUR",
		currencySymbol: "€",
		currencyText: "euros",
	},
	"forekasts-africa": {
		countrySearch: "SENEGAL",
		countryName: "SENEGAL",
		countryCode: "SEN",
		currencySearch: "XOF - Franc CFA BCEAO",
		currencyName: "Franc CFA BCEAO",
		currencyCode: "XOF",
		currencySymbol: "FCFA",
		currencyText: "FCFA",
	},
};

export const salaryCompanyLegalFormOptions = [
	{value: "SAS", label: "SAS"},
	{value: "SASU", label: "SASU"},
	{value: "SARL", label: "SARL"},
	{value: "EURL", label: "EURL"},
	{value: "SELARL", label: "SELARL"},
	{value: "SELARLU", label: "SELARLU"},
	{value: "SELAS", label: "SELAS"},
	{value: "SELASU", label: "SELASU"},
	{value: "EI", label: "Entreprise individuelle"},
	{value: "association", label: "Association"},
	{value: "autre", label: "Autre"},
];
