const sessionParameters = {
	userSessionFlags: {
		"/cgu": 0b00000000000001, // 1
		"/profile": 0b00000000000010, // 2
		"/company/onboarding": 0b00000000000100, // 4
		"/financials/feedback": 0b00000000001000, // 8
		"/project/onboarding": 0b00000000100000, // 32
		"/project/feedback": 0b00000001000000, // 64
		"/forecasts/onboarding": 0b00000100000000, // 256
		"/forecasts/feedback": 0b00001000000000, // 512
		"/report/feedback": 0b00100000000000, // 2048
		"/report/onboarding": 0b01000000000000, // 4096
		"/dashboard/onboarding": 0b10000000000000, // 8192
	},

	companySessionFlags: {
		"/company": 0b0000000000000010, // 2
		"/financials/accounts": 0b0000000000001000, // 8
		"/financials/benchmark": 0b0000000000010000, // 16
		"/project/build": 0b0000001000000000, // 512
		"/monitoring": 0b0000001000000000, // 512 // idem project
		"/dashboard": 0b0000001000000000, // 512 // idem project
		"/forecasts": 0b0000100000000000, // 2048
		"/report": 0b0010000000000000, // 8192
		"/report/download": 0b0100000000000000, // 16384
	},

	companySessionPaths: {
		0b0000000000000000: "/profile",
		0b0000000000000010: "/company",
		0b0000000000001000: "/financials/accounts",
		0b0000000000010000: "/financials/benchmark",
		0b0000001000000000: "/project/build",
		0b0000100000000000: "/forecasts",
		0b0010000000000000: "/report",
		0b0100000000000000: "/report",
	},

	companySessionProgress: {
		0b0000000000000000: 0 / 7, // "/profile",
		0b0000000000000010: 1 / 7, // "/company",
		0b0000000000001000: 2 / 7, // "/financials/accounts",
		0b0000000000010000: 3 / 7, // "/financials/benchmark",
		0b0000001000000000: 4 / 7, // "/project/build",
		0b0000100000000000: 5 / 7, // "/forecasts",
		0b0010000000000000: 6 / 7, // "/report",
		0b0100000000000000: 7 / 7, // "/report",
	},

	companySessionProgressLabels: {
		0b0000000000000000: "Non débuté",
		0b0000000000000010: "Identification de l'entreprise",
		0b0000000000001000: "États financiers",
		0b0000000000010000: "Comparaison aux pairs",
		0b0000001000000000: "Construction du prévisionnel",
		0b0000100000000000: "Simulations par scénarios",
		0b0010000000000000: "Rédaction de la synthèse",
		0b0100000000000000: "Téléchargement du rapport",
	},

	companySessionLabels: {
		"/company": "Identification de l'entreprise",
		"/financials/accounts": "États financiers",
		"/financials/benchmark": "Comparaison aux pairs",
		"/project/build": "Construction du prévisionnel",
		"/forecasts": "Simulations par scénarios",
		"/report": "Rédaction de la synthèse",
		"/report/download": "Téléchargement du rapport",
	},

	companySessionSubscriptionRequirements: {
		"/company": null,
		"/financials/accounts": null,
		"/financials/benchmark": "benchmark-access",
		"/project/build": null,
		"/forecasts": "simulations-access",
		"/report": null,
		"/report/download": "report-export",
	},

	getHighestSessionPath(sessionFlag, userAppAccess) {
		if (sessionFlag === null) {
			return "/profile";
		}

		var pos = 0;
		while ((sessionFlag >>= 1)) {
			// doubles parenthèses pour eslint
			pos++;
		}

		if (typeof this.companySessionPaths[2 ** pos] === "undefined") {
			// console.log("ERROR : UNRECOGNIZED PATH !");
			return "/login";
		}

		const path = this.companySessionPaths[2 ** pos];
		if (typeof userAppAccess === "undefined" || userAppAccess === null || userAppAccess.featuresAccess === null) {
			return path;
		} else {
			if (
				typeof this.companySessionSubscriptionRequirements[path] !== "undefined" &&
				this.companySessionSubscriptionRequirements[path] !== null &&
				userAppAccess.featuresAccess[this.companySessionSubscriptionRequirements[path]]
			) {
				return path;
			} else {
				return "/company";
			}
		}
	},

	getHighestSessionPathLabel(sessionFlag) {
		return typeof this.companySessionLabels[this.getHighestSessionPath(sessionFlag)] !== "undefined" ? this.companySessionLabels[this.getHighestSessionPath(sessionFlag)] : "";
	},

	getHighestSessionProgress(sessionFlag) {
		if (sessionFlag === null) {
			return 0;
		}

		var pos = 0;
		while ((sessionFlag >>= 1)) {
			// doubles parenthèses pour eslint
			pos++;
		}

		return typeof this.companySessionProgress[2 ** pos] !== "undefined" ? this.companySessionProgress[2 ** pos] : 0;
	},

	getHighestSessionProgressLabel(sessionFlag) {
		if (sessionFlag === null) {
			return "nd";
		}

		var pos = 0;
		while ((sessionFlag >>= 1)) {
			// doubles parenthèses pour eslint
			pos++;
		}

		return typeof this.companySessionProgressLabels[2 ** pos] !== "undefined" ? this.companySessionProgressLabels[2 ** pos] : "nd";
	},
};

export default sessionParameters;
