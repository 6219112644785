import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
// import ReactDOM from 'react-dom';
// import { hydrate, render } from 'react-dom';
import {createRoot} from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import App from "./App";
import "./index.css";

/*
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
	hydrate(
		<Router>
			<App />
		</Router>, 
		rootElement
	);
} else {
	render(
		<Router>
			<App />
		</Router>, 
		rootElement
	);
}
*/

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	// <React.StrictMode> // Pb avec react-beautiful-dnd en strict mode
	<Router>
		<App tab="home" />
	</Router>,
	// </React.StrictMode>
);
