export const sortByContributionDesc = (a, b) => b.contribution - a.contribution;

export const sortByContributionDescIndexAsc = (a, b) => {
	if (b.contribution !== a.contribution) return b.contribution - a.contribution;
	else return a.product_index - b.product_index;
};

export const untaggedProduct = {
	product_id: null,
	product_name: "Non affecté",
	product_description: "",
	product_color_code: "#E3E7EB",
	product_index: 999,
};

export const getProductContributionsSum = (total, element) => total + element.contribution;
