import React, {useState, useCallback} from "react";
import {ChromePicker, SketchPicker} from "react-color";
import {projectColors} from "./project-utils";

import "./ColorPicker.css";

export default function ColorPicker(props) {
	const {propName, onChange} = props;

	const [showPicker, setShowPicker] = useState(false);

	const handleClick = useCallback(() => {
		setShowPicker(true);
	}, []);

	const handleClose = useCallback(() => {
		setShowPicker(false);
	}, []);

	const handleChangeComplete = useCallback(
		(color) => {
			onChange(propName, color);
		},
		[propName, onChange],
	);

	return (
		<div className="colorPicker">
			<div className="colorPickerSwatch" onClick={handleClick}>
				<div className="colorPickerSwatchColor" style={{background: props.color}} />
			</div>
			{showPicker && (
				<div className="colorPickerPopover">
					<div className="colorPickerBackdrop" onClick={handleClose} />
					{props.pickerStyle === "sketch" ? (
						<SketchPicker
							color={props.color ? props.color : ""}
							disableAlpha
							onChangeComplete={handleChangeComplete}
							presetColors={projectColors.map(([c]) => c).concat(["#795548", "#607d8b", "#707070", "#404040", "#121212"])}
							// presetColors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]}
						/>
					) : (
						<ChromePicker color={props.color ? props.color : ""} disableAlpha onChangeComplete={handleChangeComplete} />
					)}
				</div>
			)}
		</div>
	);
}
