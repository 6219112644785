import React from "react";
import {Route} from "react-router-dom";
import config from "../config.js";

const PublicFrontRedirect = (props) =>
	config.appParams[window.appName] && (
		<Route
			path={props.path}
			exact
			render={() => {
				window.location.replace(config.appParams[window.appName].publicSiteUrl + props.location.pathname);
				return null;
			}}
		/>
	);
export default PublicFrontRedirect;
