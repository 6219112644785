import React from "react";
import "core-js/features/array/map";

import "./RatingScale.css";

const ratingEmojis = ["bi-emoji-heart-eyes", "bi-emoji-smile", "bi-emoji-neutral", "bi-emoji-frown", "bi-emoji-angry"];
const ratingColors = ["#04BDAD", "#90F0BC", "#FFD300", "#EA9648", "#EB6C6F"];

class Rating extends React.Component {
	handleClick = () => {
		this.props.onChange(this.props.rating);
	};

	render() {
		// console.log(this.props);
		return (
			<div className={"rate " + (this.props.checked ? " checked" : "")}>
				<button className="rateBtn" onClick={this.handleClick}>
					{this.props.ratingType === "choice" && (
						<React.Fragment>
							<div className="ratingBox">
								<i className="bi bi-check2" />
							</div>
							<div className="ratingLabel">{this.props.label}</div>
						</React.Fragment>
					)}
					{this.props.ratingType === "satisfaction" && <i className={"bi " + ratingEmojis[this.props.rating]} style={{color: ratingColors[this.props.rating]}} />}
				</button>
			</div>
		);
	}
}

class RatingScale extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			rating: this.props.rating !== null ? Number(this.props.rating) : null,
		};
	}

	handleChange = (index) => {
		var newRating = this.props.multi ? (this.state.rating !== null ? this.state.rating : 0) ^ (2 ** index) : 2 ** index;
		this.setState({rating: newRating});
		this.props.handleChange(this.props.ratingId, newRating);
	};

	render() {
		const checkboxes = this.props.labels.map((label, index) => {
			const index2 = this.props.labels.length - 1 - index;
			return (
				<Rating
					key={index2}
					ratingType={this.props.ratingType}
					checked={this.state.rating !== null && !!(this.state.rating & (2 ** index2))}
					rating={index2}
					label={label}
					onChange={this.handleChange}
				/>
			);
		});

		return <div className={"scale " + this.props.ratingType}>{checkboxes}</div>;
	}
}

export default RatingScale;
