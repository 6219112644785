import {arrayShift} from "./utils";
import {sharedContent} from "../containers/app/sharedcontent";
import {getProductContributionsSum} from "./product-utils";
// import versionConfig from '../version-config';

export const maxNbrForecastYears = 10;
export const maxNbrHistoYears = 5;

export const bpItemProps = [
	"year",
	"type",
	"title",
	"description",
	"valueType",
	"valueAmount",
	"valueVariationPercent",
	"valueCategoryPercent",
	"startMonth",
	"endMonth",
	"startYear",
	"endYear",
	"amortizable",
	"amortizationType",
	"amortizationPeriod",
	"maturity",
	"monthMaturity",
	"maturityInterval",
	"propagation",
	"quantity",
	"memQuantite",
	"frequency",
	"displayFrequence",
	"isRecurring",
	"initialStock",
	"churnRate",
	"commitmentPeriod",
	"prepaidRecurring",
	"paymentDelay",
	"defaultDelaiPaiement",
	"deposit",
	"deliveryDelay",
	"tradeMarginRate",
	"tradeMarginRateRef",
	"tradeMarginRateInfinite",
	"defaultTauxMargeCommerciale",
	"defaultTauxMargeCommercialeInfini",
	"grossMarginRate",
	"grossMarginRateRef",
	"grossMarginRateInfinite",
	"defaultTauxMargeBrute",
	"defaultTauxMargeBruteInfini",
	"stockableSupply",
	"consumableExpense",
	"vatRate",
	"interestRate",
	"amortizationDeferDelay",
	"seasonalityId",
	"splitTransaction",
	"refItemIds",
	"refItemPercent",
	"percentInputRefType",
	// "productId",
	"productContributions",
	"productMappingMode",
	"deactivated",
	"seasonalityData",
	"isMultiSelected",
	"isCapitalizedProduction",
	"customAmortizationSchedule",
	"source",
	"sourceId",
	"accountNumber",
	"subType",
	// "chargeRateMode",
	"salaryAmountType",
	"employerChargeRate",
	"employeeChargeRate",
	"numberValueType",
	"unitVariableFunctionType",
	"unitVariableReferenceType",
	"counterpartyCompany",
	"taxSystem",
	"tags",
];

export const salesBPItemTypes = ["RevNego", "RevProd", "RevServ"];

export const frequencies = [1, 2, 4, 12, 52, 260, 312, 365];

export const getBPItemStateFromProps = (props) => {
	const state = {};
	bpItemProps.forEach((key) => {
		const prop = typeof props !== "undefined" ? props[key] : null;
		state[key] = typeof prop !== "undefined" ? prop : null;
	});
	return state;
};

export const findBPItemById = (bpItems, id) => {
	var bpItem = null;
	if (bpItems) {
		for (const yearTag of Object.keys(bpItems)) {
			let yearItems = bpItems[yearTag];
			bpItem = yearItems.find((bpItem) => bpItem.id === id);
			if (bpItem) {
				bpItem.yearTag = yearTag;
				break;
			}
		}
	}
	return bpItem;
};

export const getRefItemsCategoryFilter = (type, valueType) => {
	if (valueType === "variation-percent") {
		return [type];
	} else if (valueType === "category-percent") {
		return salesBPItemTypes;
	} else if (valueType === "unit-variable-function") {
		return ["UnitVariable"];
	} else if (valueType === "amount") {
		return salesBPItemTypes;
	} else return [];
};

export const filterRefItemTypes = (bpItems, valueType, type, filterDeactivated) => {
	return (itemId) => {
		const bpItem = findBPItemById(bpItems, itemId);

		if (bpItem) {
			if (filterDeactivated && bpItem.deactivated) return false;

			/*
			if (valueType === "variation-percent") {
				return (bpItem.type === type);
			}
			else if (valueType === "category-percent") {
				return salesBPItemTypes.includes(bpItem.type);
			}
			else if (valueType === "unit-variable-function") {
				return (bpItem.type === "UnitVariable");
			}
			else if (valueType === "amount") {
				return salesBPItemTypes.includes(bpItem.type);
			}
			else return false;
			*/

			return getRefItemsCategoryFilter(type, valueType).includes(bpItem.type);
		} else return false;
	};
};

const bpItemFilterFct = (item, filterParams, bpItems) => {
	if (item.isNewBPItem) return true;

	// if (typeof bpItemTextFilter === "undefined" || bpItemTextFilter === null) return true;
	const {typeFilter, textFilter, paramsFilter, tagFilter, productId /*, companyProducts, baseForecastData, year0*/} = filterParams;

	// Type d'hypothèse

	var typeFilterResult = true;
	if (typeFilter) {
		typeFilterResult = typeFilter[item.type];
	}

	if (!typeFilterResult) return false;

	// Tag

	var tagFilterResult = true;
	if (tagFilter) {
		tagFilterResult = item.tags && item.tags.includes(tagFilter);
	}

	if (!tagFilterResult) return false;

	// Texte

	var textFilterResult = true;
	if (textFilter && textFilter.length > 0) {
		const filterRE = new RegExp(textFilter.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"), "gi");

		const itemProductNames = [];
		if (
			filterParams.isProductSegmentationActivated &&
			filterParams.companyProducts &&
			(item.productMappingMode === "input" || item.productMappingMode === null) &&
			item.productContributions
		) {
			item.productContributions.forEach((pc) => {
				const product = filterParams.companyProducts.find((cyPr) => cyPr.product_id === pc.product_id);
				if (product) {
					itemProductNames.push(product.product_name);
				}
			});
		}

		const itemTagNames = item.tags && filterParams.itemTags ? item.tags.map((tagId) => filterParams.itemTags[tagId]) : [];

		textFilterResult =
			filterRE.test(item.title) ||
			filterRE.test(item.description) ||
			filterRE.test(sharedContent.listTxtBPLong[item.type]) ||
			itemProductNames.some((productName) => filterRE.test(productName)) ||
			itemTagNames.some((tagName) => filterRE.test(tagName)) ||
			(sharedContent.listBPHasSubTypeParam[item.type] &&
				item.subType !== null &&
				typeof generalExpensesSubTypeLabels[item.subType] !== "undefined" &&
				filterRE.test(generalExpensesSubTypeLabels[item.subType])) ||
			(filterParams.accountNumbersInput &&
				typeof item.accountNumber !== "undefined" &&
				item.accountNumber !== null &&
				(filterRE.test(item.accountNumber) ||
					(filterParams.chartOfAccounts && filterParams.chartOfAccounts[item.accountNumber] && filterRE.test(filterParams.chartOfAccounts[item.accountNumber]))));
	}

	if (!textFilterResult) return false;

	// Paramètres

	if (
		paramsFilter &&
		(typeof paramsFilter.isAchieved !== "undefined" ||
			typeof paramsFilter.isPropag !== "undefined" ||
			typeof paramsFilter.isNonPropag !== "undefined" ||
			typeof paramsFilter.isAmount !== "undefined" ||
			typeof paramsFilter.isVariationPercent !== "undefined" ||
			typeof paramsFilter.isCategoryPercent !== "undefined" ||
			typeof paramsFilter.isNumber !== "undefined" ||
			typeof paramsFilter.isUnitVariableFunction !== "undefined")
	) {
		var isShown = false;

		if (paramsFilter.isAchieved === true || paramsFilter.isPropag === true || paramsFilter.isNonPropag === true) {
			if (paramsFilter.isAchieved === true && item.source === "fec" && item.sourceId !== null) isShown = true;
			if (paramsFilter.isPropag === true && item.propagation) isShown = true;
			if (paramsFilter.isNonPropag === true && !item.propagation) isShown = true;
		}

		if (
			paramsFilter.isAmount === true ||
			paramsFilter.isVariationPercent === true ||
			paramsFilter.isCategoryPercent === true ||
			paramsFilter.isNumber === true ||
			paramsFilter.isUnitVariableFunction === true
		) {
			if (paramsFilter.isAmount === true && item.valueType === "amount") isShown = true;
			if (paramsFilter.isVariationPercent === true && item.valueType === "variation-percent") isShown = true;
			if (paramsFilter.isCategoryPercent === true && item.valueType === "category-percent") isShown = true;
			if (paramsFilter.isNumber === true && item.valueType === "number") isShown = true;
			if (paramsFilter.isUnitVariableFunction === true && item.valueType === "unit-variable-function") isShown = true;
		}

		if (!isShown) return false;
	}

	if (paramsFilter && paramsFilter.isIntraGroup === true && item.counterpartyCompany === null) {
		return false;
	}

	// Produit

	if (!filterParams.isProductSegmentationActivated || typeof productId === "undefined") return true;

	const {contributionValue, contributionFactor} = getItemProductContribution(item, filterParams, bpItems);
	if (contributionValue !== 0 || contributionFactor !== 0) return true;

	return false;
};

const getItemProductContribution = (item, filterParams, bpItems) => {
	const result = {
		contributionValue: 0,
		contributionFactor: 0,
	};

	const {productId, companyProducts, baseForecastData, year0} = filterParams;

	if (item.valueType === "amount" || item.valueType === "number") {
		if (item.productMappingMode === "sales") {
			const accountItem = sharedContent.BPItemTypeToAccountName[item.type];

			if (
				accountItem &&
				baseForecastData &&
				baseForecastData.forecasts &&
				baseForecastData.forecasts[accountItem] &&
				baseForecastData.forecasts[accountItem].forecastContributions
			) {
				const forecastContribution = baseForecastData.forecasts[accountItem].forecastContributions.find((fc) => fc.id === item.id);

				if (forecastContribution) {
					const yearNumber = item.year - year0;
					result.contributionValue = forecastContribution.contribution[(yearNumber - 1) * 12 + 11];
				}
			}
		} else {
			result.contributionFactor = getItemProductContributionFactor(item, productId, companyProducts);
		}
	} else if (item.valueType === "variation-percent" || item.valueType === "category-percent") {
		// On cherche les produits des hypothèses de ref

		if (item.refItemIds) {
			for (let iRef = 0; iRef < item.refItemIds.length; iRef++) {
				let refItem = findBPItemById(bpItems, item.refItemIds[iRef]);

				if (refItem) {
					const {contributionValue, contributionFactor} = getItemProductContribution(refItem, filterParams, bpItems);

					if (contributionValue !== 0 || contributionFactor !== 0) {
						result.contributionValue = contributionValue;
						result.contributionFactor = contributionFactor;
						break;
					}
				}
			}
		} else {
			for (const yearTag of Object.keys(bpItems)) {
				let yearItems = bpItems[yearTag];
				let nbItems = yearItems.length;

				for (let iItem = 0; iItem < nbItems; iItem++) {
					let refItem = yearItems[iItem];

					if (
						(item.valueType === "variation-percent" && refItem.type === item.type && refItem.year < item.year) ||
						(item.valueType === "category-percent" && salesBPItemTypes.includes(refItem.type))
					) {
						if (refItem.productMappingMode === "sales") {
							const accountItem = sharedContent.BPItemTypeToAccountName[refItem.type];

							if (
								accountItem &&
								baseForecastData &&
								baseForecastData.forecasts &&
								baseForecastData.forecasts[accountItem] &&
								baseForecastData.forecasts[accountItem].forecastContributions
							) {
								const forecastContribution = baseForecastData.forecasts[accountItem].forecastContributions.find((fc) => fc.id === refItem.id);

								if (forecastContribution) {
									const yearNumber = refItem.year - year0;
									const contribution = forecastContribution.contribution[(yearNumber - 1) * 12 + 11];

									if (contribution !== 0) {
										result.contributionValue = contribution;
										break;
									}
								}
							}
						} else {
							const {contributionValue, contributionFactor} = getItemProductContribution(refItem, filterParams, bpItems);

							if (contributionValue !== 0 || contributionFactor !== 0) {
								result.contributionValue = contributionValue;
								result.contributionFactor = contributionFactor;
								break;
							}
						}
					}
				}

				if (result.contributionValue !== 0 || result.contributionFactor !== 0) break;
			}
		}
	} else if (item.valueType === "unit-variable-function") {
		// On cherche les produits des hypothèses de ref

		if (item.refItemIds) {
			for (let iRef = 0; iRef < item.refItemIds.length; iRef++) {
				let refItem = findBPItemById(bpItems, item.refItemIds[iRef]);

				if (refItem) {
					const {contributionValue, contributionFactor} = getItemProductContribution(refItem, filterParams, bpItems);

					if (contributionValue !== 0 || contributionFactor !== 0) {
						result.contributionValue = contributionValue;
						result.contributionFactor = contributionFactor;
						break; // à checker - pquoi s'arrêter à la 1ère trouvée
					}
				}
			}
		}
	}

	return result;
};

export const getFilteredBPItems = (bpItems, filterParams) => {
	const newBPItems = {...bpItems};
	Object.keys(newBPItems).forEach((yTag) => {
		newBPItems[yTag] = newBPItems[yTag].filter((item) => bpItemFilterFct(item, filterParams, bpItems));
	});
	return newBPItems;
};

export const getSeasonalityData = (props, id) => {
	var seasonalityData = null;
	if (id !== null && props.accountCorrectionModeBasicUI !== true) {
		seasonalityData = props.seasonalities.find((item) => item.id === id);
		if (typeof seasonalityData === "undefined") {
			seasonalityData = null;
		} else {
			seasonalityData = {
				...seasonalityData,
				factors: arrayShift(seasonalityData.factors, props.fiscalYearFirstMonthIndex),
			};
			if (seasonalityData.unitAmounts) seasonalityData.unitAmounts = arrayShift(seasonalityData.unitAmounts, props.fiscalYearFirstMonthIndex);
		}
	}
	return seasonalityData;
};

export const getRecurringCumulAmount = (valueAmount, quantities, fiscalYearFirstMonthIndex, startMonth, endMonth, churnRate, commitmentPeriod, initialStock, prepaidRecurring) => {
	const nbrCumulatedInitSubscriptions = Array(12).fill(0);
	const nbrCumulatedNewSubscriptions = Array(12).fill(0);
	const nbrCumulatedTotalSubscriptions = Array(12).fill(0);
	const nbrNewSubscriptions = Array(12).fill(0);
	const nbrExpiringInitSubscriptions = Array(12).fill(0);
	const nbrExpiringNewSubscriptions = Array(12).fill(0);
	// const nbrExpiringTotalSubscriptions = Array(12).fill(0);
	const nbrRecommittedInitSubscriptions = Array(12).fill(0);
	const nbrRecommittedNewSubscriptions = Array(12).fill(0);
	const nbrRecommittedTotalSubscriptions = Array(12).fill(0);
	const nbrLostInitSubscriptions = Array(12).fill(0);
	const nbrLostNewSubscriptions = Array(12).fill(0);
	// const nbrLostTotalSubscriptions = Array(12).fill(0);
	const mrr = Array(12).fill(0);

	if (typeof initialStock === "undefined") initialStock = 0;

	var cumulAmount = 0;
	var cumulQuantities = 0;
	var cumulExpiringInitSubscriptionsRemainder = 0;
	var cumulLostInitSubscriptionsRemainder = 0;
	var cumulLostNewSubscriptionsRemainder = 0;

	const shiftedStartMonth = (startMonth - fiscalYearFirstMonthIndex + 12) % 12;
	const shiftedEndMonth = (endMonth - fiscalYearFirstMonthIndex + 12) % 12;

	for (let mIdx = 0; mIdx < 12; mIdx++) {
		let laggedIdx = mIdx - commitmentPeriod;

		/*
		if (initialStock !== 0) {
			nbrCumulatedTotalSubscriptions[mIdx] = (commitmentPeriod === 1 ? (
				// si engagement 1m, formule en loi géométrique exacte
				Math.round(initialStock * (1-churnRate)**mIdx)
			) : (
				// Sinon formule loi de survie exponentielle
				Math.round(initialStock * Math.exp(-churnRate/commitmentPeriod * mIdx))
			));
		}
		*/

		if (initialStock !== 0) {
			nbrExpiringInitSubscriptions[mIdx] =
				mIdx < commitmentPeriod
					? // Sur les 1ers mois, écoulement linéaire du stock initial
						initialStock / commitmentPeriod
					: nbrRecommittedInitSubscriptions[laggedIdx];

			// On retraite pour arriver à des nombres entiers
			let expiringSubscriptionsInt = Math.floor(nbrExpiringInitSubscriptions[mIdx]);
			let expiringSubscriptionsRemainder = nbrExpiringInitSubscriptions[mIdx] - expiringSubscriptionsInt;
			cumulExpiringInitSubscriptionsRemainder += expiringSubscriptionsRemainder;
			nbrExpiringInitSubscriptions[mIdx] = expiringSubscriptionsInt + Math.floor(cumulExpiringInitSubscriptionsRemainder);
			cumulExpiringInitSubscriptionsRemainder -= Math.floor(cumulExpiringInitSubscriptionsRemainder);

			// Version avec tombée à partir du mois 1
			// let lostSubscriptionsInt = (mIdx > 0 ? Math.floor(churnRate * nbrExpiringInitSubscriptions[mIdx]) : 0);
			// let lostSubscriptionsRemainder = (mIdx > 0 ? churnRate * nbrExpiringInitSubscriptions[mIdx] - lostSubscriptionsInt : 0);

			// Version avec tombée à partir du mois 0
			/*// A ACTIVER AVEC GESTION DOSSIERS EXISTANTS */
			let lostSubscriptionsInt = Math.floor(churnRate * nbrExpiringInitSubscriptions[mIdx]);
			let lostSubscriptionsRemainder = churnRate * nbrExpiringInitSubscriptions[mIdx] - lostSubscriptionsInt;

			cumulLostInitSubscriptionsRemainder += lostSubscriptionsRemainder;
			nbrLostInitSubscriptions[mIdx] = lostSubscriptionsInt + Math.floor(cumulLostInitSubscriptionsRemainder);
			cumulLostInitSubscriptionsRemainder -= Math.floor(cumulLostInitSubscriptionsRemainder);
			nbrRecommittedInitSubscriptions[mIdx] = nbrExpiringInitSubscriptions[mIdx] - nbrLostInitSubscriptions[mIdx];
			nbrCumulatedInitSubscriptions[mIdx] = (mIdx === 0 ? initialStock : nbrCumulatedInitSubscriptions[mIdx - 1]) - nbrLostInitSubscriptions[mIdx];
			nbrCumulatedTotalSubscriptions[mIdx] = nbrCumulatedInitSubscriptions[mIdx];
		}

		// let isMonthContributing = (mIdx <= (endMonth - startMonth + 12) % 12);
		let isMonthContributing = shiftedEndMonth >= shiftedStartMonth ? mIdx >= shiftedStartMonth && mIdx <= shiftedEndMonth : mIdx >= shiftedStartMonth || mIdx >= shiftedEndMonth;

		if (isMonthContributing) {
			// let q = Math.round(quantities[(mIdx + startMonth) % 12]);
			let q = Math.round(quantities[(mIdx + fiscalYearFirstMonthIndex) % 12]);
			nbrNewSubscriptions[mIdx] = q;
			cumulQuantities += q;
		}
		// console.log(mIdx, isMonthContributing, nbrNewSubscriptions[mIdx]);

		nbrExpiringNewSubscriptions[mIdx] = laggedIdx >= 0 ? nbrNewSubscriptions[laggedIdx] + nbrRecommittedNewSubscriptions[laggedIdx] : 0;
		let lostSubscriptionsInt = Math.floor(churnRate * nbrExpiringNewSubscriptions[mIdx]);
		let lostSubscriptionsRemainder = churnRate * nbrExpiringNewSubscriptions[mIdx] - lostSubscriptionsInt;
		cumulLostNewSubscriptionsRemainder += lostSubscriptionsRemainder;
		nbrLostNewSubscriptions[mIdx] = lostSubscriptionsInt + Math.floor(cumulLostNewSubscriptionsRemainder);
		cumulLostNewSubscriptionsRemainder -= Math.floor(cumulLostNewSubscriptionsRemainder);
		nbrRecommittedNewSubscriptions[mIdx] = nbrExpiringNewSubscriptions[mIdx] - nbrLostNewSubscriptions[mIdx];
		nbrCumulatedNewSubscriptions[mIdx] =
			mIdx === 0 ? nbrNewSubscriptions[mIdx] : nbrCumulatedNewSubscriptions[mIdx - 1] + nbrNewSubscriptions[mIdx] - nbrLostNewSubscriptions[mIdx];
		nbrCumulatedTotalSubscriptions[mIdx] += nbrCumulatedNewSubscriptions[mIdx];

		nbrRecommittedTotalSubscriptions[mIdx] = nbrRecommittedInitSubscriptions[mIdx] + nbrRecommittedNewSubscriptions[mIdx];
		mrr[mIdx] = prepaidRecurring
			? (nbrNewSubscriptions[mIdx] + nbrRecommittedTotalSubscriptions[mIdx]) * valueAmount * commitmentPeriod
			: nbrCumulatedTotalSubscriptions[mIdx] * valueAmount;
		cumulAmount += mrr[mIdx];
	}

	return {
		amount: cumulAmount,
		quantity: cumulQuantities,
	};
};

export const getItemTotalValueAmount = ({
	fiscalYearFirstMonthIndex,
	startMonthIdx,
	endMonthIdx,
	seasonalityData,
	productId,
	companyProducts,
	baseForecastData,
	yearNumber,
	accountCorrectionModeBasicUI,
	isMicroCompany,
	countryCode,
	...item
}) => {
	var isQuantityDecimal = false;
	var totalValueAmount = 0;
	var productValueAmount = 0;
	var totalQuantity = 0;

	if (item.valueType === "amount" || item.valueType === "number") {
		totalValueAmount = item.valueAmount * item.quantity * (item.valueType === "number" && item.numberValueType === "stock" ? 1 : item.frequency);

		if (countryCode === "FRA" && sharedContent.listBPHasAmountChargeRateParam[item.type](isMicroCompany)) {
			if (item.employerChargeRate !== null && item.employeeChargeRate !== null) {
				if (item.salaryAmountType === "net" && item.employeeChargeRate !== 1) {
					totalValueAmount *= (1 + item.employerChargeRate) / (1 - item.employeeChargeRate);
				} else if (item.salaryAmountType === "gross") {
					totalValueAmount *= 1 + item.employerChargeRate;
				}
			}
		}
		totalQuantity = item.quantity * (item.valueType === "number" && item.numberValueType === "stock" ? 1 : item.frequency);

		if (item.valueType !== "number" && item.isRecurring) {
			const quantities = seasonalityData ? seasonalityData.factors.map((f) => (f / 100) * item.quantity * item.frequency) : Array(12).fill((item.quantity * item.frequency) / 12);

			isQuantityDecimal = quantities.some((q) => (Math.round(q * 10) / 10) % 1 !== 0);

			const {amount, quantity} = getRecurringCumulAmount(
				item.valueAmount,
				quantities,
				fiscalYearFirstMonthIndex,
				startMonthIdx,
				endMonthIdx,
				item.churnRate,
				item.commitmentPeriod,
				item.initialStock,
				item.prepaidRecurring,
			);

			totalValueAmount = amount;
			totalQuantity = quantity;
		}
		//
		else {
			if (
				(((sharedContent.listBPIncomeStatementItem[item.type] || item.type === "UnitVariable") && sharedContent.listBPPropagatableParam[item.type]) ||
					(sharedContent.listBPSeasonalitySwitchParam[item.type] && item.splitTransaction)) &&
				!item.propagation &&
				!accountCorrectionModeBasicUI
			) {
				var totalFactor = 0;
				if (endMonthIdx >= startMonthIdx) {
					for (let iM = startMonthIdx; iM <= endMonthIdx; iM++) {
						totalFactor += seasonalityData ? seasonalityData.factors[iM] / 100 : 1 / 12;
					}
				} else {
					for (let iM = startMonthIdx; iM < 12; iM++) {
						totalFactor += seasonalityData ? seasonalityData.factors[iM] / 100 : 1 / 12;
					}
					for (let iM = 0; iM <= endMonthIdx; iM++) {
						totalFactor += seasonalityData ? seasonalityData.factors[iM] / 100 : 1 / 12;
					}
				}

				totalValueAmount *= totalFactor;
				totalQuantity *= totalFactor;
			}
		}

		productValueAmount = totalValueAmount;

		if (typeof productId !== "undefined") {
			/*
			var productContributionFactor = 0;
			
			if (item.productMappingMode === "input" || item.productMappingMode === null) {
				
				if (item.productContributions && productId !== null) {
					
					const productContribution = item.productContributions.find(pc => pc.product_id === productId);
					
					if (productContribution) {
						productContributionFactor = productContribution.contribution / 10000;
					}
				}
				else if (item.productContributions && productId === null) {
					
					const totalContribution = item.productContributions.filter(pc => pc.product_id !== null).reduce(getProductContributionsSum, 0);
					
					if (Math.round(totalContribution) < 10000) {
						productContributionFactor = Math.max(10000 - totalContribution, 0) / 10000;
					}
				}
			}
			else if (item.productMappingMode === "parity") {
				
				if (companyProducts && companyProducts.length > 0) {
					
					if (productId !== null) {
						productContributionFactor = 1 / companyProducts.length;
					}
				}
				else {
					
					if (productId === null) {
						productContributionFactor = 1;
					}
				}
			}
			else 
			*/
			if (item.productMappingMode === "sales") {
				const accountItem = sharedContent.BPItemTypeToAccountName[item.type];

				if (
					accountItem &&
					baseForecastData &&
					baseForecastData.forecasts &&
					baseForecastData.forecasts[accountItem] &&
					baseForecastData.forecasts[accountItem].forecastContributions
				) {
					const forecastContribution = baseForecastData.forecasts[accountItem].forecastContributions.find((fc) => fc.id === item.id);

					if (forecastContribution) {
						productValueAmount = forecastContribution.contribution[(yearNumber - 1) * 12 + 11];
					} else {
						productValueAmount = 0;
					}
				}
			} else {
				const productContributionFactor = getItemProductContributionFactor(item, productId, companyProducts);

				productValueAmount = totalValueAmount * productContributionFactor;

				// if (item.valueType === "number") {
				// totalQuantity *= productContributionFactor;
				// }
			}
		}
	}

	return {
		totalValueAmount: totalValueAmount,
		productValueAmount: productValueAmount,
		totalQuantity: totalQuantity,
		isQuantityDecimal: isQuantityDecimal,
	};
};

const getItemProductContributionFactor = (item, productId, companyProducts) => {
	var productContributionFactor = 0;

	if (item.productMappingMode === "input" || item.productMappingMode === null) {
		if (item.productContributions && productId !== null) {
			const productContribution = item.productContributions.find((pc) => pc.product_id === productId);

			if (productContribution) {
				productContributionFactor = productContribution.contribution / 10000;
			}
		} else if (productId === null) {
			if (item.productContributions) {
				const totalContribution = item.productContributions.filter((pc) => pc.product_id !== null).reduce(getProductContributionsSum, 0);

				if (Math.round(totalContribution) < 10000) {
					productContributionFactor = Math.max(10000 - totalContribution, 0) / 10000;
				}
			} else {
				productContributionFactor = 1;
			}
		}
	} else if (item.productMappingMode === "parity") {
		if (companyProducts && companyProducts.length > 0) {
			if (productId !== null) {
				productContributionFactor = 1 / companyProducts.length;
			}
		} else {
			if (productId === null) {
				productContributionFactor = 1;
			}
		}
	} else if (item.productMappingMode === "sales") {
		productContributionFactor = 1;
	}

	return productContributionFactor;
};

export const generalExpensesSubTypes = [
	"energy",
	"rent",
	"insurance",
	"telecommunication",
	"tools-licences",
	"leasing",
	"training",
	"external-staff",
	"bank-fees",
	"events",
	"public-relations",
	"advertising",
	"web-hosting",
	"general-outsourcing",
	"sales-outsourcing",
	"finance-outsourcing",
	"hr-outsourcing",
	"recruitment-fees",
	"it-outsourcing",
	"marketing-outsourcing",
	"admin-outsourcing",
	"design-outsourcing",
	"research",
	"legal-fees",
	"accounting-fees",
	"consulting-fees",
	"brokerage-fees",
	"commissions",
	"printing",
	"architecture",
	"shipping",
	"supplies",
	"business-trips",
	"catering",
	"maintenance",
	"meeting",
	"subscriptions",
	"business-gifts",
	"other",
];

export const generalExpensesSubTypeLabels = {
	energy: "Énergie",
	rent: "Loyer",
	insurance: "Assurances",
	telecommunication: "Télécommunication",
	"tools-licences": "Abonnements logiciels et outils",
	leasing: "Redevances de crédit-bail, locations",
	training: "Formations",
	"external-staff": "Personnel extérieur / intérimaire",
	"bank-fees": "Frais bancaires",
	events: "Événementiel, salons, expositions",
	"public-relations": "Relations publiques",
	advertising: "Publicité",
	"web-hosting": "Hébergement site internet",
	"general-outsourcing": "Prestations de services / Sous-traitance générale",
	"sales-outsourcing": "Sous-traitance commerciale",
	"finance-outsourcing": "Sous-traitance financière",
	"hr-outsourcing": "Sous-traitance RH",
	"recruitment-fees": "Frais de recrutement de personnel",
	"it-outsourcing": "Sous-traitance informatique",
	"marketing-outsourcing": "Sous-traitance marketing",
	"admin-outsourcing": "Sous-traitance administrative",
	"design-outsourcing": "Prestations de graphisme / design",
	research: "Études, recherche",
	"legal-fees": "Honoraires juridiques",
	"accounting-fees": "Honoraires comptables",
	"consulting-fees": "Honoraires d'expertise-conseil",
	"brokerage-fees": "Honoraires de courtage",
	commissions: "Commissions sur ventes",
	printing: "Frais d'impression / publication",
	architecture: "Honoraires d'architecture",
	shipping: "Frais de transport / stockage",
	supplies: "Fournitures, matériel",
	"business-trips": "Frais de déplacements",
	catering: "Restauration",
	maintenance: "Frais d'entretien, réparations, maintenance",
	meeting: "Frais de séminaires, conférences",
	subscriptions: "Cotisations, adhésions",
	"business-gifts": "Cadeaux à la clientèle",
	other: "Autre",
};

export const valTypeDelayMetrics = {
	RevNego: "delai_paiement_clients_negoce",
	RevProd: "delai_paiement_clients_production",
	RevServ: "delai_paiement_clients_services",
	CoutsMarch: "delai_paiement_fournisseurs_marchandises",
	CoutsMatPrem: "delai_paiement_fournisseurs_matieres_premieres",
	CoutsExt: "delai_paiement_fournisseurs_externes",
};
export const valTypeTauxMargeCommercialeMetrics = {
	RevNego: "taux_marge_commerciale_negoce",
};
export const valTypeTauxMargeBruteMetrics = {
	RevProd: "taux_marge_brute_production",
};

export const getBpItemDefaultExtraPropValues = (props) => {
	var defaultDelaiPaiement = 0;
	if (typeof valTypeDelayMetrics[props.type] !== "undefined") {
		let metricLabel = valTypeDelayMetrics[props.type];
		if (
			props.initMetrics &&
			props.initMetrics[metricLabel] &&
			props.initMetrics[metricLabel].annualValues &&
			typeof props.initMetrics[metricLabel].annualValues[props.yearLabel] !== "undefined"
		) {
			if (props.initMetrics[metricLabel].annualValues[props.yearLabel] !== null) {
				let val = Number(props.initMetrics[metricLabel].annualValues[props.yearLabel]);
				if (!isNaN(val)) {
					defaultDelaiPaiement = val;
				}
			}
		}
	}

	var defaultTauxMargeCommerciale = 0;
	var defaultTauxMargeCommercialeInfini = false;
	if (typeof valTypeTauxMargeCommercialeMetrics[props.type] !== "undefined") {
		let metricLabel = valTypeTauxMargeCommercialeMetrics[props.type];
		if (
			props.initMetrics &&
			props.initMetrics[metricLabel] &&
			props.initMetrics[metricLabel].annualValues &&
			typeof props.initMetrics[metricLabel].annualValues[props.yearLabel] !== "undefined"
		) {
			if (props.initMetrics[metricLabel].annualValues[props.yearLabel] !== null) {
				let val = props.initMetrics[metricLabel].annualValues[props.yearLabel];
				if (val === "infinity" || val === "-infinity") {
					defaultTauxMargeCommercialeInfini = true;
				} else {
					let numVal = Number(val);
					if (!isNaN(numVal)) {
						defaultTauxMargeCommerciale = numVal;
					}
				}
			}
		}
	}

	var defaultTauxMargeBrute = 0;
	var defaultTauxMargeBruteInfini = false;
	if (typeof valTypeTauxMargeBruteMetrics[props.type] !== "undefined") {
		let metricLabel = valTypeTauxMargeBruteMetrics[props.type];
		if (
			props.initMetrics &&
			props.initMetrics[metricLabel] &&
			props.initMetrics[metricLabel].annualValues &&
			typeof props.initMetrics[metricLabel].annualValues[props.yearLabel] !== "undefined"
		) {
			if (props.initMetrics[metricLabel].annualValues[props.yearLabel] !== null) {
				let val = props.initMetrics[metricLabel].annualValues[props.yearLabel];
				if (val === "infinity" || val === "-infinity") {
					defaultTauxMargeBruteInfini = true;
				} else {
					let numVal = Number(val);
					if (!isNaN(numVal)) {
						defaultTauxMargeBrute = numVal;
					}
				}
			}
		}
	}

	return {
		defaultDelaiPaiement,
		defaultTauxMargeCommerciale,
		defaultTauxMargeCommercialeInfini,
		defaultTauxMargeBrute,
		defaultTauxMargeBruteInfini,
	};
};

export const projectColors = [
	["#FF6700", "#FFFFFF", "light"],
	["#FE9100", "#FFFFFF", "light"],
	["#FDBA00", "#FFFFFF", "light"],
	["#C9EC42", "#404040", "dark"],
	["#82DB42", "#FFFFFF", "light"],
	["#4CD646", "#FFFFFF", "light"],
	["#48CC74", "#FFFFFF", "light"],
	["#45D3AA", "#FFFFFF", "light"],
	["#41E5F4", "#404040", "dark"],
	["#4AA3F0", "#FFFFFF", "light"],
	["#6478E3", "#FFFFFF", "light"],
	["#7B62D4", "#FFFFFF", "light"],
	["#A968D6", "#FFFFFF", "light"],
	["#EB154E", "#FFFFFF", "light"],
	["#F15079", "#FFFFFF", "light"],
	["#F78DA8", "#FFFFFF", "light"],
];

export const defaultProjectColorIndexes = [
	// 8,
	// 6,
	// 5,
	// 2,
	// 10,
	// 13,
	// 7,
	// 3,
	// 11,
	// 14,
	// 15,
	// 1,
	// 9,
	// 4,
	// 12,
	// 0,
	10, 6, 15, 8, 4, 0, 12, 5, 13, 9, 1, 7, 11, 2, 14, 3,
];
