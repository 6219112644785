import React from "react";
// import Isvg2 from 'react-inlinesvg2';
import Isvg from "react-inlinesvg";

// export default (props) => <Isvg wrapper={React.createFactory('div')} className={typeof props.className !== "undefined" ? props.className : ""} src={require("../images/icons/" + props.svgName + ".svg")} />;
// export default (props) => ((props.src || props.svgName) ? (
const SvgWrap = (props) => {
	if (!props.src && !props.svgName) return null;

	var svgSrc = null;

	if (typeof props.src !== "undefined") {
		if (typeof props.src === "string") {
			svgSrc = props.src;
		} else if (typeof props.src.default === "string") {
			svgSrc = props.src.default;
		}
	} else if (typeof props.svgName === "string") {
		const s = require("../images/icons/" + props.svgName + ".svg");
		if (typeof s === "string") {
			svgSrc = s;
		} else if (typeof s.default === "string") {
			svgSrc = s.default;
		}
	}

	return (
		svgSrc !== null &&
		React.createElement(
			typeof props.wrapperTag !== "undefined" ? props.wrapperTag : "div",
			{
				className: "isvg " + (typeof props.className !== "undefined" ? props.className : ""),
			},
			// <Isvg2 src={typeof props.src !== "undefined" ? props.src : require("../images/icons/" + props.svgName + ".svg").default} />
			<Isvg src={svgSrc} />,
		)
	);
};
export default SvgWrap;
