import tinycolor from "tinycolor2";

export const getReadableColor = (hexColor) => {
	var tc = tinycolor(hexColor);
	while (tinycolor.readability(tc, "#FFF") < 1.5) {
		tc = tc.darken(5);
	}
	return tc.toHexString();
};

export const getLighterColor = (hexColor, factor) => {
	return tinycolor.mix(tinycolor(hexColor), "#FFF", factor).toHexString();
};

export const lighten = (hexColor, factor) => {
	return tinycolor(hexColor).lighten(factor).toHexString();
};

export const darken = (hexColor, factor) => {
	return tinycolor(hexColor).darken(factor).toHexString();
};

export const hexToRgb = (hexColor) => {
	return tinycolor(hexColor).toRgb();
};

export const isValidHexColor = (hexColor) => {
	return tinycolor(hexColor).isValid();
};
