import React from "react";
import {StackableModal, Modal} from "./Modal";
// import {ChromePicker} from "react-color";
import ColorPicker from "./ColorPicker";

import "../App.css";
import "./AppThemeManager.css";

const themePrefix = "--fk-";

const themeItemNames = [
	"side-menu-main-bg-color",
	"side-menu-secondary-bg-color",
	"side-menu-emphasis-color",
	"color-text-standard",
	"color-btn-link-clickable",
	"color-selection-selected",
	"color-text-emphasis",
	"color-category-revenues",
	"color-category-expenses",
	"color-category-assets",
	"color-category-liabilities",
	"color-category-other",
	"color-category-unit-variables",
];

const themeItemLabels = {
	"side-menu-main-bg-color": "Menu latéral : background principal",
	"side-menu-secondary-bg-color": "Menu latéral : background secondaire",
	"side-menu-emphasis-color": "Menu latéral : couleur hover",
	"color-text-standard": "Texte standard",
	"color-btn-link-clickable": "Liens / boutons",
	"color-selection-selected": "Sélection",
	"color-text-emphasis": "Texte accentué",
	"color-category-revenues": "Revenus",
	"color-category-expenses": "Dépenses",
	"color-category-assets": "Investissements",
	"color-category-liabilities": "Financements",
	"color-category-other": "Gestion",
	"color-category-unit-variables": "Variables élémentaires",
};

export const defaultAppStyles = {
	// "-color-text-standard": "#5C5656",
	// "-color-text-standard-rgb": "92, 86, 86",
	"-color-text-standard": "#21242F",
	"-color-text-standard-rgb": "33, 36, 47",
	"-color-text-dark-grey": "#404040",
	// "-color-btn-link-clickable": "#129FDD",
	// "-color-btn-link-clickable-rgb": "18, 159, 221",
	"-color-btn-link-clickable": "#3379E6",
	"-color-btn-link-clickable-rgb": "51, 121, 230",
	// "-color-selection-selected": "#5682B2",
	// "-color-selection-selected-rgb": "86, 130, 178",
	"-color-selection-selected": "#2534A8",
	"-color-selection-selected-rgb": "37, 52, 168",
	// "-color-text-emphasis": "#5682B2",
	"-color-text-emphasis": "#2534A8",
	"-color-text-emphasis-rgb": "37, 52, 168",
	"-color-text-success": "#04BDAD",
	"-color-text-success-rgb": "4, 189, 173",
	"-color-text-success-light": "#90F0BC",
	"-color-inactive": "#B8B8BA",
	"-color-disabled": "#E3E7EB",
	"-color-error": "#EB6C6F",
	"-color-category-assets": "#04BDAD",
	"-color-category-liabilities": "#5682B2",
	"-color-category-revenues": "#43E3E8",
	"-color-category-expenses": "#90F0BC",
	"-color-category-other": "#2B9BBC",
	"-color-category-unit-variables": "#FDF0A7",
	// "-color-category-revenues-darker": "#18bfc4",
	// "-color-category-expenses-darker": "#19b761",
	"-color-category-margin": "#4AA3F0",
	"-color-category-cash": "#3142C6",
};

export const whiteLabelCustomItemNames = ["navbar-color", "side-menu-main-bg-color", "side-menu-secondary-bg-color", "side-menu-emphasis-color"];

export const whiteLabelCustomItemStyles = {
	"navbar-color": "#2B5E93",
	"side-menu-main-bg-color": "#16395F",
	"side-menu-secondary-bg-color": "#1B4775",
	"side-menu-emphasis-color": "#458AD3",
};

export const oldMenuItemNames = [
	"--fk-navbar-height",
	"--fk-color-text-standard",
	"--fk-color-text-standard-rgb",
	"--fk-color-btn-link-clickable",
	"--fk-color-selection-selected",
	"--fk-color-selection-selected-rgb",
	"--fk-color-text-emphasis",
];

export const oldMenuItemStyles = {
	"--fk-navbar-height": "50px",
	"--fk-color-text-standard": "#5C5656",
	"--fk-color-text-standard-rgb": "92, 86, 86",
	"--fk-color-btn-link-clickable": "#129FDD",
	"--fk-color-selection-selected": "#5682B2",
	"--fk-color-selection-selected-rgb": "86, 130, 178",
	"--fk-color-text-emphasis": "#5682B2",
};

/*
export class ColorPicker extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showPicker: false,
		};
	}

	handleClick = () => {
		this.setState({showPicker: true});
	};

	handleClose = () => {
		this.setState({showPicker: false});
	};

	handleChangeComplete = (color) => {
		this.props.onChange(this.props.themeItemName, color);
		// this.setState({showPicker: false});
	};

	render() {
		return (
			<div className="colorPicker">
				<div className="colorPickerSwatch" onClick={this.handleClick}>
					<div className="colorPickerSwatchColor" style={{background: this.props.color}} />
				</div>
				{this.state.showPicker && (
					<div className="colorPickerPopover">
						<div className="colorPickerBackdrop" onClick={this.handleClose} />
						<ChromePicker color={this.props.color ? this.props.color : ""} disableAlpha onChangeComplete={this.handleChangeComplete} />
					</div>
				)}
			</div>
		);
	}
}
*/

export class AppThemeManager extends React.Component {
	constructor(props) {
		super(props);

		this.initState = {};

		const root = document.querySelector(":root");
		const rootStyle = root ? getComputedStyle(root) : null;

		themeItemNames.forEach((themeItemName) => {
			this.initState[themeItemName] =
				rootStyle && typeof rootStyle.getPropertyValue(themePrefix + themeItemName) !== "undefined"
					? rootStyle.getPropertyValue(themePrefix + themeItemName)
					: defaultAppStyles[themeItemName];
		});

		this.state = {...this.initState};
	}

	handleChange = (themeItemName, color) => {
		// console.log(color);

		this.setState({
			[themeItemName]: color.hex,
		});
	};

	handleSave = () => {
		/*
		const root = document.querySelector(":root");
		if (root) {
			
			themeItemNames.forEach(themeItemName => {
				root.style.setProperty(themePrefix + themeItemName, this.state[themeItemName]);
			});
		}
		*/

		const styles = themeItemNames.map((themeItemName) => ({
			styleItemName: themeItemName,
			styleItemValue: this.state[themeItemName],
		}));

		this.props.handleUpdateAppStyles(styles);

		this.props.onHide();
	};

	render() {
		return (
			<StackableModal show={this.props.show} dialogClassName="" onHide={this.props.onHide}>
				<Modal.Body>
					<table>
						<tbody>
							{themeItemNames.map((themeItemName) => (
								<tr key={themeItemName}>
									<td>{themeItemLabels[themeItemName]}</td>
									<td>
										<ColorPicker propName={themeItemName} color={this.state[themeItemName]} onChange={this.handleChange} />
										<span className="marginLeft10">{this.state[themeItemName]}</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</Modal.Body>

				<Modal.Footer className="ModalActions">
					<button onClick={this.props.onHide} className="b-main-action b-main-alt">
						Annuler
					</button>
					<button onClick={this.handleSave} className="b-main-action">
						Enregistrer
					</button>
				</Modal.Footer>
			</StackableModal>
		);
	}
}
