import React, {useRef, useCallback} from "react";
import {Modal as RBModal} from "react-bootstrap";
import {customAlphabet} from "nanoid/non-secure";

import "../App.css";
import "./Modal.css";

const nanoid = customAlphabet("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", 8);

export function StackableModal(props) {
	const {onEnter, blurryBackdrop} = props;

	const _id = useRef("m_" + nanoid());

	const handleEnter = useCallback(() => {
		const maxModalZIndex = Math.max(...[...document.querySelectorAll(".modal.show")].map((node) => parseFloat(window.getComputedStyle(node).zIndex)));
		if (!isNaN(maxModalZIndex)) {
			const backdropNode = document.querySelector(".stackableModalBackdrop." + _id.current);
			if (backdropNode) backdropNode.style.zIndex = maxModalZIndex + 10;
			const modalNode = document.querySelector(".modal." + _id.current);
			if (modalNode) {
				modalNode.style.zIndex = maxModalZIndex + 20;
				if (blurryBackdrop) {
					modalNode.style.backdropFilter = "blur(4px)";
				}
			}
		}

		if (typeof onEnter !== "undefined") onEnter();
	}, [blurryBackdrop, onEnter]);

	const modalProps = {
		...props,
		className: _id.current,
		dialogClassName: (props.dialogClassName ? props.dialogClassName + " " : "") + "stackableModalDialog " + _id.current,
		backdropClassName: (props.backdropClassName ? props.backdropClassName + " " : "") + "stackableModalBackdrop " + _id.current,
		onEnter: handleEnter,
	};

	delete modalProps.blurryBackdrop; // pour éviter warning react / prop non reconnue

	return <RBModal {...modalProps} />;
}

export function ModalCloseButton(props) {
	return (
		<button className="closeBtn" onClick={props.onClick}>
			&times;
		</button>
	);
}

export function ModalHeader(props) {
	return (
		<RBModal.Header className={props.className ? props.className : ""}>
			<div>{props.children}</div>
			{typeof props.onCloseButtonClick !== "undefined" && <ModalCloseButton onClick={props.onCloseButtonClick} />}
		</RBModal.Header>
	);
}

export function ModalBody(props) {
	return <RBModal.Body className={props.className ? props.className : ""}>{props.children}</RBModal.Body>;
}

export function ModalFooter(props) {
	return <RBModal.Footer className={props.className ? props.className : ""}>{props.children}</RBModal.Footer>;
}

export const Modal = RBModal;
