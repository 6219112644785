import React from "react";
import {StackableModal, Modal} from "./Modal";
import config from "../config";
import axios from "axios";
import {crmLogPostFeedback} from "./crmEvents";
import RatingScale from "./RatingScale";
import {EditableTextArea} from "./EditableText";

import "../App.css";
import "./UserFeedbacks.css";

/*
export const feedbackNames = {
	"/project/build": "project",
	"/forecasts": "simulation",
	"/report": "report",
};
*/

const ratingLabels = ["A++", "A+", "A", "A-", "A--"];

export class FeedbackManager extends React.Component {
	constructor(props) {
		super(props);

		this.initState = {
			showFeedbackSent: false,
			feedbackSendingError: false,
			rating: null,
			field1: "",
			// field2: "",
		};

		this.state = {
			...this.initState,
			showFeedback: null,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.showFeedback && !prevProps.showFeedback) {
			this.handleShowFeedback();
		}
	}

	handleEnter = () => {
		this.setState({
			...this.initState,
		});
	};

	handleShowFeedback = () => {
		this.setState({
			...this.initState,
			showFeedback: this.props.showFeedback,
		});
	};

	handleClose = () => {
		this.setState({
			...this.initState,
			showFeedback: null,
		});
	};

	handleBack = () => {
		this.setState({
			showFeedbackSent: false,
			feedbackSendingError: false,
		});
	};

	handleChangeFeedbackRating = (ratingId, ratings) => {
		this.setState({rating: Number(ratings)});
	};

	handleChangeFeedbackField = (newVal) => {
		this.setState({field1: newVal});
	};

	handleSaveFeedback = () => {
		const feedbackScoreLabel = this.state.rating !== null && this.state.rating > 0 ? ratingLabels[Math.log2(this.state.rating)] : "";

		if (config.gtmTracking && typeof window.dataLayer !== "undefined") {
			window.dataLayer.push({
				event: "post_feedback",
				email: this.props.userEmail,
				content_category: "use_app_action",
				content_type: "post_feedback_" + this.state.showFeedback,
				feedback_score: this.state.rating,
				feedback_score_label: feedbackScoreLabel,
				feedback_text: this.state.field1,
			});
		}

		crmLogPostFeedback({
			feedbackStep: this.state.showFeedback,
			feedbackScore: this.state.rating,
			feedbackScoreLabel: feedbackScoreLabel,
			feedbackText: this.state.field1,
		});

		const apiBaseUrl = config.api.server[window.serverName];

		const url = apiBaseUrl + config.api.root + "/user/feedback";

		const payload = {
			feedbacks: [
				{
					feedbackUrl: this.state.showFeedback,
					score: this.state.rating,
					text: this.state.field1,
				},
			],
		};

		axios
			.post(url, payload, {
				headers: {
					"x-access-token": localStorage.getItem("token"),
					appname: window.appName,
				},
			})
			.then(() => {
				this.setState({
					showFeedbackSent: true,
					feedbackSendingError: false,
				});
			})
			.catch((error) => {
				if (error.response && error.response.status === 498) {
					this.props.logout(true);
				} else {
					console.log(error);
					this.setState({
						showFeedbackSent: true,
						feedbackSendingError: true,
					});
				}
			});
	};

	isSaveEnabled = () => {
		return (this.state.rating !== null && this.state.rating !== 0) || (this.state.field1 && this.state.field1.length > 0);
	};

	render() {
		return (
			<StackableModal show={this.state.showFeedback !== null} onHide={this.handleClose} dialogClassName="feedbackModalDialog" keyboard onEnter={this.handleEnter} centered>
				<Modal.Header>
					<Modal.Title>Faites-nous part de votre avis !</Modal.Title>
				</Modal.Header>

				{this.state.showFeedbackSent ? (
					<>
						<Modal.Body>
							<div className="centerAlign marginTop20">
								{this.state.feedbackSendingError ? (
									<>
										<p>Une erreur s&apos;est produite.</p>
										<p>Nous vous invitons à réessayer.</p>
									</>
								) : (
									<>
										<p>Merci !</p>
										<p>Votre retour a bien été pris en compte.</p>
									</>
								)}
							</div>
						</Modal.Body>

						<Modal.Footer className="ModalActions">
							{this.state.feedbackSendingError && (
								<button className="linkBtn noUnderline" onClick={this.handleBack}>
									<i className="bi bi-chevron-left marginRight" />
									Retour
								</button>
							)}
							<button className="b-main-action" onClick={this.handleClose}>
								Fermer
							</button>
						</Modal.Footer>
					</>
				) : (
					<>
						<Modal.Body>
							<div className="marginTop20">
								<RatingScale
									ratingType="satisfaction"
									ratingId={this.state.showFeedback}
									rating={this.state.rating}
									handleChange={this.handleChangeFeedbackRating}
									labels={ratingLabels}
								/>
							</div>

							<div className="marginTop20">
								<p>Avez-vous des difficultés rencontrées, des suggestions d&apos;amélioration ?</p>
								<EditableTextArea
									inputClassName="feedbackTextArea"
									defaultValue={this.state.field1}
									maxCharacters={500}
									onChange={this.handleChangeFeedbackField}
									displayNbCharHelper={false}
								/>
							</div>
						</Modal.Body>

						<Modal.Footer className="ModalActions">
							<button className="b-main-action b-main-alt" onClick={this.handleClose}>
								Annuler
							</button>
							<button className="b-main-action" onClick={this.handleSaveFeedback} disabled={!this.isSaveEnabled()}>
								Envoyer
							</button>
						</Modal.Footer>
					</>
				)}
			</StackableModal>
		);
	}
}
